<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Bulks</span>
    </el-card>
    <div class="table-container">
      <el-table
        ref="table"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Captain">
          <template slot-scope="scope">{{ scope.row.captainName }}</template>
        </el-table-column>
        <el-table-column label="Product">
          <template slot-scope="scope">{{ scope.row.productName }}</template>
        </el-table-column>
        <el-table-column label="Start Time">
          <template slot-scope="scope">{{
            new Date(scope.row.startTime).toString()
          }}</template>
        </el-table-column>
        <el-table-column label="End Time">
          <template slot-scope="scope">{{
            new Date(scope.row.endTime).toString()
          }}</template>
        </el-table-column>
        <el-table-column label="Consumer Count">
          <template slot-scope="scope">{{ scope.row.consumerCount }}</template>
        </el-table-column>
        <el-table-column label="Max Member">
          <template slot-scope="scope">{{ scope.row.memberLimit }}</template>
        </el-table-column>
        <el-table-column label="Threshold">
          <template slot-scope="scope">{{
            scope.row.successMemberThreshold
          }}</template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">{{ scope.row.bulkStatus }}</template>
        </el-table-column>
        <el-table-column label="Operations">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              v-if="scope.row.bulkStatus == 'Created' || scope.row.bulkStatus == 'In progress'"
              @click="terminate(scope.row.id)"
              >Terminate
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.count"
        :page-sizes="[5, 10, 15, 20]"
        :current-page.sync="listQuery.page"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { fetchAll, terminate as terminateBulk } from '@/api/bulk';

const defaultListQuery = {
  page: 1,
  count: 20,
};
export default {
  name: 'bulks',
  data() {
    return {
      list: null,
      listLoading: true,
      listQuery: { ...defaultListQuery },
      total: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const param = JSON.parse(JSON.stringify(this.listQuery));
      param.page -= 1;

      fetchAll(param).then((response) => {
        this.list = response.data.data;
        this.total = response.data.recordsTotal;
        this.listLoading = false;
      });
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.count = val;
      this.getList();
    },
    terminate(id) {
      this.$confirm('Terminate Bulk?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        terminateBulk(id).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
  },
};
</script>
